<template>
    <div class="pc-animation" :class="active">
        <img class="img-1" :src="img1" />
        <img class="img-2" :src="img2" />
        <img class="img-3" :src="img3" />
        <img class="img-4" :src="img4" />
        <img class="img-5" :src="img5" />
        <img class="img-6" :src="img6" />
    </div>
</template>

<script>
import img0 from '../../assets/images/common/icon/1.png'
import img1 from '../../assets/images/common/icon/2.png'
import img2 from '../../assets/images/common/icon/3.png'
import img3 from '../../assets/images/common/icon/4.png'
import img4 from '../../assets/images/common/icon/5.png'
import img5 from '../../assets/images/common/icon/6.png'
import img6 from '../../assets/images/common/icon/7.png'
import img7 from '../../assets/images/common/icon/8.png'
import img8 from '../../assets/images/common/icon/9.png'
import img9 from '../../assets/images/common/icon/10.png'
import img10 from '../../assets/images/common/icon/11.png'
import img11 from '../../assets/images/common/icon/12.png'
import img12 from '../../assets/images/common/icon/13.png'
import img13 from '../../assets/images/common/icon/14.png'
import img14 from '../../assets/images/common/icon/15.png'
import img15 from '../../assets/images/common/icon/16.png'
import img16 from '../../assets/images/common/icon/17.png'
import img17 from '../../assets/images/common/icon/18.png'
export default {
    name: 'PcAnimatioin',
    data() {
        return {
            active: '',
            animated: false,
            currentTop: 0,
            img1: img0,
            img2: img1,
            img3: img2,
            img4: img3,
            img5: img4,
            img6: img5,
            goTimeout: null,
            hideTimeout: null
        }
    },
    watch: {
        animated(v) {
            if (!v) {
                if (this.currentTop < 2000) {
                    this.img1 = img0
                    this.img2 = img1
                    this.img3 = img2
                    this.img4 = img3
                    this.img5 = img4
                    this.img6 = img5
                }
                if (this.currentTop >= 2000 && this.currentTop < 4000) {
                    this.img1 = img6
                    this.img2 = img7
                    this.img3 = img8
                    this.img4 = img9
                    this.img5 = img10
                    this.img6 = img11
                } else {
                    this.img1 = img12
                    this.img2 = img13
                    this.img3 = img14
                    this.img4 = img15
                    this.img5 = img16
                    this.img6 = img17
                }
            }
        }
    },
    computed: {
    },
    mounted() {
        window.onscroll = (e) => {
            if (e.target.documentElement.scrollTop - this.currentTop <= 0) {
                this.currentTop = e.target.documentElement.scrollTop
                return
            }
            this.currentTop = e.target.documentElement.scrollTop
            if (!this.animated) {
                this.animation()
            }
        }
        this.animation()
    },
    methods: {
        animation() {
            if (this.goTimeout) {
                clearTimeout(this.goTimeout)
            }
            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout)
            }
            this.animated = true
            this.goTimeout = setTimeout(() => {
                this.active = 'go'
            }, 100)
            this.hideTimeout = setTimeout(() => {
                this.active = 'hide'
                this.animated = false
            }, 4000)
        }
    }
}
</script>

<style lang="less">
.pc-animation {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    pointer-events: none;
    img {
        position: absolute;
        transform: scale(0.7) translateY(0);
        &.img-1 {
            top: -90px;
            left: 1%;
        }
        &.img-2 {
            top: -100px;
            left: 15%;
        }
        &.img-3 {
            top: -80px;
            left: 30%;
        }
        &.img-4 {
            top: -120px;
            right: 30%;
        }
        &.img-5 {
            top: -130px;
            right: 15%;
        }
        &.img-6 {
            top: -170px;
            right: 1%;
        }
    }
    &.go {
        img {
            visibility: visible;
            transform: scale(0.5) translateY(2000px);
            &.img-1 {
                transition:  transform 4s;
            }
            &.img-2 {
                transition:  transform 2s;
            }
            &.img-3 {
                transition:  transform 3s;
            }
            &.img-4 {
                transition:  transform 2s;
            }
            &.img-5 {
                transition:  transform 4s;
            }
            &.img-6 {
                transition:  transform 3s;
            }
        }
    }
    &.hide {
        img {
            visibility: hidden;
        }
    }
}
</style>
