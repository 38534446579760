<template>
    <div class="pc-header">
        <div class="header-content">
            <div class="logo">
                <img src="../../assets/images/pc/logo.png" />
            </div>
            <div class="content">
                <div class="nav">
                    <div class="nav-item">
                        <span :class="{active: active === 0}"><a href="#bolwup" @click="active = 0">{{$t('header1')}}</a></span>
                    </div>
                    <div class="nav-item">
                        <span :class="{active: active === 1}"><a href="#tokenomics" @click="active = 1">{{$t('header2')}}</a></span>
                    </div>
                    <div class="nav-item">
                        <span :class="{active: active === 2}"><a href="#buy" @click="active = 2">{{$t('header3')}}</a></span>
                    </div>
                    <div class="nav-item">
                        <span :class="{active: active === 3}"><a href="#roadmap" @click="active = 3">{{$t('header4')}}</a></span>
                    </div>
                </div>
            </div>
<!--            <div class="right">-->
<!--                <span>-->
<!--                    <img src="../../assets/images/pc/locale.png" />-->
<!--                </span>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'headerComp',
    data() {
        return {
            active: -1
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="less">
.pc-header {
    width: 100%;
    background: #D0423F;
    height: 70px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
    .header-content {
        display: flex;
        align-items: center;
        width: 1200px;
        justify-content: space-between;
        .logo {
            display: flex;
            height: 100%;
            img {
                height: 100%;
            }
        }
        .content {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .nav {
                display: flex;
                .nav-item {
                    padding-left: 48px;
                }
                span {
                    font-family: Hamish;
                    color: #fff;
                    font-size: 24px;
                    cursor: pointer;
                    a {
                        color: #fff;
                    }
                    &.active {
                        a {
                            color: #FFD917;
                        }
                    }
                }
            }
        }
        .right {
            width: 60px;
            display: flex;
            align-items: center;
            padding-left: 48px;
            span {
                height: 32px;
                img {
                    height: 32px;
                }
            }
        }
    }
}
</style>
