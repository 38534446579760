<template>
    <div id="app">
        <div class="page-pc">
            <headerNav></headerNav>
            <router-view></router-view>
            <pc-footer />
            <animation />
        </div>
    </div>
</template>

<script>
import headerNav from '../components/pc/header'
import pcFooter from '../components/pc/footer'
import animation from '../components/pc/animation'
export default {
    name: 'PCLayout',
    components: {
        headerNav,
        pcFooter,
        animation
    }
}
</script>

<style lang="less">
@import "../less/pc/main";
.page-pc {
    width: 100%;
    min-height: 100%;
}
</style>
